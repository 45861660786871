import React, { useEffect } from "react";
import * as _ from './style'

const AmoCrmForm = () => {

    // useEffect(() => {
    //     const loadAmoFormsScript = () => {
    //         const script = document.createElement('script');
    //         script.id = 'amoforms_script_1161974';
    //         script.async = true;
    //         script.charset = 'utf-8';
    //         script.src = 'https://forms.amocrm.ru/forms/assets/js/amoforms.js?1687867078';

    //         const formDiv = document.getElementById('amoCRMform')
    //         formDiv.appendChild(script);
    //     };
    //     return loadAmoFormsScript();
    // }, []);

    return (
        <_.Container>
            <div id={'amoCRMform'} data-aos={"fade-right"}>
            </div>
        </_.Container>
    );
};

export default AmoCrmForm;