import React, { useState, lazy, Suspense, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { AiOutlineSearch } from 'react-icons/ai';

import StyledSearchContainer, { StyledDropDownContainer } from './style';

import BaseStyledInput from 'Common/Style/styledInput';
import BaseStyledFlex from 'Common/Style/styledFlex';

const Form = lazy(() => import('./form'));

const GlobalSearch = () => {
  const { t } = useTranslation(['common']);
  const containerRef=useRef(null);
  const arrowContainerRef=useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onArrowClickHandler = () => {
    setIsOpen(!isOpen);
  };

  // const closeSearchContainer = (e) => {
  //   let el = e.target, baseVal =e.target.className?.baseVal ;
  //   let is=containerRef.current.contains(e.target) || arrowContainerRef.current.contains(e.target) || baseVal === 'arrow-up' || baseVal === 'arrow-down' ;
  //   if (!is) {
  //     setIsOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('click', closeSearchContainer);

  //   return () => {
  //     document.removeEventListener('click', closeSearchContainer);
  //   };
  // }, []);

  return (
    <StyledSearchContainer className="justify-content-center align-items-center">
      <BaseStyledFlex>
        {/* <BaseStyledFlex className="field-container">
          <BaseStyledInput placeholder={t('search')} style={{ height: '100%', width: '100%' }} />
        </BaseStyledFlex> */}

        {/* <BaseStyledFlex
          ref={arrowContainerRef}
          id="global-search-arrow-container"
          className="justify-content-center align-items-center arrow-container"
          onClick={onArrowClickHandler}>
          {!isOpen ? <IoIosArrowDown className="arrow-down" /> : <IoIosArrowUp className="arrow-up" />}
        </BaseStyledFlex> */}

        <BaseStyledFlex className="justify-content-center align-items-center zoom-container">
          <AiOutlineSearch />
        </BaseStyledFlex>
      </BaseStyledFlex>

      <StyledDropDownContainer ref={containerRef} id="global-search-container" className={classnames({ visible: isOpen, hide: !isOpen })}>
        <Suspense fallback={<div />}>
          <Form />
        </Suspense>
      </StyledDropDownContainer>
    </StyledSearchContainer>
  );
};

export default GlobalSearch;
