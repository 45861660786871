import styled from 'styled-components';
import get from 'lodash.get';
import { colors } from 'Style/colors';
import { Col, Layout, Radio, Row, Form, Button, Input } from 'antd';
import leftImageLoginPage from "Assets/images/WhiteAndRedPoster.jpg";
const { Content } = Layout;

export const StyledForm = styled(Form)``;

export const LoginWrapper = styled.div`
  @media (max-width: 600px) {
    
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 10px;

  @media (max-width: 1400px) {
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    width: 94%;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const LoginContent = styled(Content)`
  height: 100vh;
  width: 100%;
  background: ${colors.white};
  & > div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  & .ant-form-item-control {
    width: 85%;
    margin: auto;
  }
`;

export const LoginLeftCol = styled(Col)`
  height: auto;
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;

  @media (max-width: 600px) {
   display: none;
  }
`;

export const LoginRightCol = styled(Col)`
  height: 100vh;
  width: 33vw;
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 600px) {
    width: 100vw;
    padding: 10px;
   }
`;

export const StyledRow = styled(Row)``;

export const RightContainer = styled.div`
  max-width: 480px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 10px;
  margin: auto;
  gap: 20px;
  position: relative;
  
  .authing {
    color: #404190;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }

  @media (max-width: 600px) {
    .authing {
      padding: 0 10px;
     text-align: center;
    }
  }

  @media (max-width: 1400px) {
    padding: 5px 20px;
    gap: 20px;
  }

  & > div {
    &:nth-child(1) {
    }

    &:nth-child(2) {
    }

    &:nth-child(2) > svg {
      width: 100%;
      height: 100%;
    }

    &:nth-child(3) {
      & > span {
        text-align: center;
      }
      & > * {
        display: block;
        margin: auto;
      }
      & .ant-select {
        width: 100%;
        margin: 15px auto;
        border: ${({ errorStatus }) => (errorStatus === 'empty_field' ? `1px solid ${colors.warning}` : '')};

        @media (max-width: 1400px) {
          margin: 10px auto;
        }
      }
    }
    &:nth-child(4) {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`;

export const LangDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px 0px;
  margin-left: auto;

  @media (max-width: 1400px) {
    padding: 8px 0px;
  }

  .ant-select-selector {
    color: black;
    border-color: black !important;
  }
  .ant-select-arrow {
    color: black;
  }
`;

export const SvgDiv = styled.div`
  max-width: 252.57px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 1400px) {
    max-width: 293.539px;
    height: auto;
  }

  @media (max-width: 600px) {
    max-width: 253.539px;
    height: auto;
  }
`;

export const LeftContainer = styled.div`
  width: 67vw;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 14, 58, 0.2), rgba(0, 14, 58, 0.2)), url(${(props) => props.img});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
`;

export const LeftTop = styled.div`
  padding: 4px 30px;
`;

export const LeftBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
`;

export const LeftBottomRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  gap: 12px;
`;

export const PhoneDiv = styled.div``;

export const Text = styled.p`
  color: #ffffff;
  text-align: center;
  margin: 0px;
  font-style: normal;

  @media (max-width: 600px) {
      gap: 2px;

      & > svg {
        margin: 0px !important;
        display: none !important;
      }
  }

  ${(props) => props.md && 'font-weight: 500; font-size: 20px; line-height: 20px; letter-spacing: 0.15px;'};

  ${(props) => props.sm && 'font-weight: 500; font-size: 16px; line-height: 20px; letter-spacing: 0.15px;'};

  ${(props) => props.esm && 'font-weight: 400; font-size: 12px; line-height: 16px; letter-spacing: 0.4px;'};

  ${(props) => props.esmw && 'font-weight: 700; text-align: left; font-size: 12px; line-height: 16px; letter-spacing: 0.4px;'};

  ${(props) => props.radio && 'display: flex;  align-items: center; gap: 8px;'};

  ${(props) => props.color && `color: ${props.color};`};
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 16px !important;

  @media (max-width: 600px) {
    padding: 0 10px;
    gap: 8px !important;
  }
`;

export const StyledRadio = styled(Radio)`
  min-width: 208px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  border: 1px solid #1f87e5;
  border-radius: 8px;
  padding: 12px;
  margin: 0px;
  gap: 8px;

  ${(props) => (props.isActive ? `border: 1px solid #1F87E5;` : `border: 1px solid #E3E5EC;`)};

  @media (max-width: 1400px) {
    min-width: 180px;
    padding: 6px 0px 6px 6px;
    gap: 0px;
  }

  @media (max-width: 600px) {
    min-width: 160px;
  }
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 50px; /* Adjust the width to your desired size */
  height: 50px; /* Adjust the height to your desired size */
  text-align: right;
`;

export const Badge = styled.div`
  font-size: 8px; /* Adjust the font size to your desired size */
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 16px; /* Adjust the line height to your desired size */
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 70px; /* Adjust the width to your desired size */
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 14px; /* Adjust the top position to position the badge properly */
  right: -15px; /* Adjust the right position to position the badge properly */
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 2px solid #79a70a; /* Adjust the border width to your desired size */
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-top: 2px solid #79a70a; /* Adjust the border width to your desired size */
  }
  &:after {
    content: '';
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 2px solid transparent;
    border-right: 2px solid #79a70a; /* Adjust the border width to your desired size */
    border-bottom: 2px solid transparent;
    border-top: 2px solid #79a70a; /* Adjust the border width to your desired size */
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 15px 13px 5px;
  gap: 10px;
  border: 1px solid #d9d9d9;
  background: #f2f3f4;
  border-radius: 8px;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.5px !important;
  color: #8b94b2 !important;
`;

export const StyledErrorText = styled.p`
  color: red;
  margin-bottom: 0;
  margin-top: 8px;
  font-size: 12px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px;
  background: #1f87e5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  max-width: 430px;
  margin: auto;

  @media (max-width: 600px) {
    max-width: 350%;
    width: 91%;
    margin-top:20px;
  }
`;

export const AgainButton = styled(Button)`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin: auto;
  border: 0;
  color: green;
  margin-bottom: 1rem;
`;

export const RightBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 9px;
  margin-top: auto;

  @media (max-width: 1400px) {
    padding: 14px 0px 0px;
  }
`;

export const ArrowLeft = styled.a`
  position: absolute;
  top: 20px;
  left: 10px;
  padding: 2px 10px 0;
  border-radius: 6px;
  color: black;
  font-size: 20px;
  img {
    height: 40px;
  }
`;

export const SecondLeftSideWrapper = styled.div`
  width: 67vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: linear-gradient(0deg, rgba(0, 14, 58, 0.2), rgba(0, 14, 58, 0.2)), url(${leftImageLoginPage});
  background: url(${leftImageLoginPage});
  background-size: cover;
  background-position: center;
  position: relative;

  .block {
    width: 85%;
    height: 70%;
    padding: 40px 40px;
    display: flex;
    background-color: white;
    box-shadow: 4px 4px 4px 0px rgba(31, 135, 229, 0.10);
    border-radius: 10px;


    .col-1 {
      display: block;
      width: 70%;

      img {
        height: 35px;
        margin-bottom: 40px;
      }

      h1 {
        font-weight: bold;
        color: ${colors.primary};
        font-size: 38px;
        line-height: 46px; /* 104.545% */
      }

      p {
        font-size: 16px;
        line-height: normal;
      }

      button {
        background: ${colors.primary};
        color: white;
        font-size: 18px;
        line-height: normal;
      }
    }

    .col-2 {
      align-self: flex-end;

      img {
        width: 510px;
      }
    }
  }
  //img {
  //  width: 80%;
  //  border-radius: 10px;
  //}
`;

export const SecondLeftBottom = styled.div`
  p {
    color: #ffffff;
    margin: 0;
    font-style: normal;
  }
  
  .compName {
    position: absolute;
    left: 40px;
    bottom: 40px;
    p {
      font-weight: 500;
    }
  }
  .callCenter {
    position: absolute;
    right: 40px;
    bottom: 30px;
    display: flex;
    
    svg {
      margin-right: 10px;
    }
    
    p:first-child {
      font-weight: 400;
      font-size: 12px;
    }
    p:last-child {
      font-weight: 500;
      font-size: 14px;
    }
  }
`;
